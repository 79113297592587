<script>
export default {
  name: 'FormTitle',
  render(createElement) {
    return createElement('div', { class: 'form-title' }, this.$slots.default)
  }
}
</script>

<style scoped lang="sass">
.form-title
  @extend %H324B
  color: $black
  margin-bottom: rem(32)
</style>
